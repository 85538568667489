export const Header = () => <>
	<header id="header" className="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 70}">
		<div className="header-body border-top-0">
			<div className="header-container container">
				<div className="header-row">
					<div className="header-column">
						<div className="header-row">
							<div className="header-logo">
								<a href="/">
									<img src="../img/myhealthily-logo.png" alt="" width="200" height="55" data-sticky-width="180" data-sticky-height="49"/>
								</a>
							</div>
						</div>
					</div>
					<div className="header-column justify-content-end">
						<div className="header-row">
							<div
								className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
								<div
									className="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
									<nav className="collapse">
										<ul className="nav nav-pills" id="mainNav">
											<li className="dropdown"><a href="/how-it-works">Platform</a>
												<ul className="dropdown-menu">
													<li><a className="dropdown-item" href="/how-it-works">How it Works</a></li>
													<li><a className="dropdown-item" href="/marketing-support">Marketing Support</a></li>
													<li className="dropdown"><a href="/faqs">FAQs</a></li>
													<li><a className="dropdown-item" href="https://app.myhealthily.com/shop/agency/signup" target="_new">Sign Up &nbsp; <i className="fa fa-external-link" aria-hidden="true"></i></a></li>
												</ul>
											</li>
											<li className="dropdown"><a href="/blog">Blog</a></li>
											<li className="dropdown"><a href="/about-myhealthily">Company</a>
												<ul className="dropdown-menu">
													<li><a className="dropdown-item" href="/about-myhealthily">About</a></li>
													<li><a className="dropdown-item" href="/contact">Contact</a></li>
													<li><a className="dropdown-item" href="/press-releases">Press Releases</a></li>
													<li><a className="dropdown-item" href="/careers">Careers</a></li>
												</ul>
											</li>
											<li><a href="/book-demo"><span className="badge badge-primary badge-md">BOOK YOUR DEMO</span></a></li>
											<li><a href="https://app.myhealthily.com/" target="_new"><span className="badge badge-quaternary badge-md">LOGIN</span></a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
	</>;